import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => x.webContent, T.html`<div class="products-page">
    <h1>${x => x.webContent.page_title}</h1>

    <div class="description" :innerHTML="${x => x.webContent.description}"></div>
    
    <div class="product-blocks">
        ${T.repeat(x => x.webContent.productsList, T.html`
            <div class="block">
                ${T.when(block => block.productImage.length, T.html`<div class="block-photos">
                    <splide-slider :options="${ (x,c) => c.parent.glideOpts }" :images="${block => block.productImage.map((suluImage: any) => ({ 
                        small: suluImage.thumbnails.small_front_img, 
                        big: suluImage.thumbnails.big_front_img
                     }))}"></splide-slider>
                </div>`)}
                <div class="block-title">
                    ${block => block.productName}
                </div>
                <div class="block-description" :innerHTML="${block => block.productDescription}">
                    
                </div>
                
                <div class="block-prices">
                    <div class="price${block => block.productDiscountedPrice ? ' with-discount' : ''}">            
                        <span class="price-value">${block => block.productPrice}</span><span class="currency">PLN</span>
                    </div>
                    ${T.when(block => block.productDiscountedPrice, T.html`<div class="discount-price">
                        <span class="price-value">${block => block.productDiscountedPrice}</span><span class="currency">PLN</span>
                    </div>`)}
                </div>                   
            </div>
        `)}
    </div>
</div>`)}
`;

import './styles/layout.scss';
const styles = T.css`.products-page {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.products-page * {
  box-sizing: border-box;
}
.products-page h1 {
  font-size: 3rem;
  color: var(--heading-color, #E9C476);
  margin: 2rem 0 1rem 0;
  padding: 0;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
}
.products-page .content {
  font-size: 1.2rem;
  line-height: 1.5;
}
.products-page .product-blocks {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.products-page .product-blocks .block {
  flex: 0 0 calc(33.3333333333% - 0.5rem);
  max-width: calc(33.3333333333% - 0.5rem);
  border-top: 2px solid #FFF;
  border-bottom: 2px solid #FFF;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
}
.products-page .product-blocks .block .block-title {
  font-size: 3rem;
  color: var(--heading-color, #E9C476);
  margin: 2rem 0 1rem 0;
  padding: 0;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
  font-size: 1.5rem;
  margin: 0.5rem 0 0.5rem 0;
  padding-bottom: 0.5rem;
}
.products-page .product-blocks .block .block-prices {
  width: 100%;
  margin: 1rem 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.products-page .product-blocks .block .block-prices .price {
  font-size: 1.4rem;
  color: var(--heading-color, #E9C476);
  font-family: "Bungee", sans-serif;
  font-weight: 400;
  margin-right: 0.3rem;
}
.products-page .product-blocks .block .block-prices .price.with-discount {
  font-size: 1rem;
  color: var(--primary-color, #ffffff);
  font-weight: 400;
  text-decoration: line-through;
  opacity: 0.7;
}
.products-page .product-blocks .block .block-prices .discount-price {
  font-size: 1.4rem;
  color: var(--heading-color, #E9C476);
  font-weight: 400;
}
@media (max-width: 768px) {
  .products-page .product-blocks {
    gap: 0;
  }
  .products-page .product-blocks .block {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 768px) and (max-width: var(--rws-md-width, 1200px)) {
  .products-page .product-blocks .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) and (max-width: var(--rws-sm-width, 992px)) {
  .products-page .product-blocks .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) and (max-width: var(--rws-xs-width, 768px)) {
  .products-page .product-blocks .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .products-page .product-blocks .block .block-title {
    font-size: 1.2rem;
  }
  .products-page .product-blocks .block .block-prices .price {
    font-size: 1.2rem;
  }
  .products-page .product-blocks .block .block-prices .discount-price {
    font-size: 1.2rem;
  }
}
@media (max-width: 480px) {
  .products-page .product-blocks {
    gap: 1rem;
  }
  .products-page .product-blocks .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 480px) and (max-width: var(--rws-md-width, 1200px)) {
  .products-page .product-blocks .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 480px) and (max-width: var(--rws-sm-width, 992px)) {
  .products-page .product-blocks .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 480px) and (max-width: var(--rws-xs-width, 768px)) {
  .products-page .product-blocks .block {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 480px) {
  .products-page .product-blocks .block .block-title {
    font-size: 1rem;
  }
  .products-page .product-blocks .block .block-prices .price {
    font-size: 1rem;
  }
  .products-page .product-blocks .block .block-prices .discount-price {
    font-size: 1rem;
  }
}`;

const shadowOptions = {"mode":"open"};
import { RWSView, observable, RWSViewComponent, attr } from '@rws-framework/client';
import Splide, { Options as SplideOptions } from '@splidejs/splide';

@RWSView('products-page', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ProductPageComponent extends RWSViewComponent  {
    @attr pageData: string;
    @observable webContent: any;

    @observable options: SplideOptions = {
        type: 'loop',
        perPage: 1,
        autoplay: true,
        interval: 3000,
        arrows: true,
        pagination: true,
    };

    connectedCallback(): void {
        super.connectedCallback();

        this.initializeSplide();        
    }

    initializeSplide(): void {
        const splideElement = this.shadowRoot?.querySelector('.splide');
        if (splideElement) {
            new Splide(splideElement as HTMLElement, this.options).mount();
        }
    }
}

ProductPageComponent.defineComponent();

export { ProductPageComponent };

interface Product {
    productName: string;
    productImage: string;
    productDescription: string;
}