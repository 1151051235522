import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`${T.when(x => x.webContent, T.html`<div class="projects-page">
    <h1>${x => x.webContent.page_title}</h1>
    
    ${T.repeat(x => x.webContent.blocks, T.html`
        <div class="block">
            <div class="block-title">
                ${block => block.blockTitle}
            </div>
            <div class="block-date">
                ${block => block.blockDate}
            </div>
            <div class="block-photos">
                ${T.when(block => block.blockPhotos.length, T.html`<div class="block-photos">
                    <splide-slider :images="${block => block.blockPhotos.map((suluImage: any) => ({ 
                        small: suluImage.thumbnails.wide_front_img, 
                        big: suluImage.thumbnails.big_front_img
                     }))}"></splide-slider>
                </div>`)}
            </div>
        </div>
    `)}
</div>`)}
`;

import './styles/layout.scss';
const styles = T.css`.projects-page {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.projects-page h1 {
  font-size: 3rem;
  color: var(--heading-color, #E9C476);
  margin: 2rem 0 1rem 0;
  padding: 0;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
}
.projects-page .content {
  font-size: 1.2em;
  line-height: 1.5;
}

.block {
  border-top: 2px solid #FFF;
  border-bottom: 2px solid #FFF;
  padding: 0.3rem;
  margin-bottom: 1.5rem;
}
.block .block-title {
  font-size: 3rem;
  color: var(--heading-color, #E9C476);
  margin: 2rem 0 1rem 0;
  padding: 0;
  font-weight: 400;
  font-family: "Bungee", sans-serif;
  font-size: 1.5rem;
  margin: 0.5rem 0 0.5rem 0;
  padding-bottom: 0.5rem;
}`;

const shadowOptions = {"mode":"open"};
import { RWSView, observable, RWSViewComponent, attr } from '@rws-framework/client';
import { IProjectsPage } from 'src/types/pages/IProjectsPage';

@RWSView('projects-page', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class ProjectsPageComponent extends RWSViewComponent  {
    @attr pageData: string;
    @observable webContent: IProjectsPage;

    async connectedCallback(): Promise<void> {
        super.connectedCallback();        
    }
}

ProjectsPageComponent.defineComponent();

export { ProjectsPageComponent };
