import * as T from '@microsoft/fast-element';
import './template.html';

                
//@ts-ignore                
let rwsTemplate: any = T.html`<website-header :isOn="${ x => x.showTopMenu }" pageType="${ x => x.pageType }" :menu="${ x => x.topMenu}"></website-header>
<main class="${ x => x.loading ? 'loading' : ''}">
</main>
`;

import './styles/layout.scss';
const styles = T.css`main {
  transition: opacity 0.4s ease-in-out;
}
main.loading {
  opacity: 0;
}`;

const shadowOptions = {"mode":"open"};
import { RWSViewComponent, RWSView, observable, attr, RWSInject } from '@rws-framework/client';
import { IDefaultPage } from '../../types/pages/IDefaultPage';
import { MenuEntry } from '../../types/MenuEntry';
import StylingService, { StylingServiceInstance } from '../../services/StylingService';


@RWSView('entry-point', null, { template: rwsTemplate, styles, options: {shadowOptions} })
class EntryPoint extends RWSViewComponent  {          
    @attr pageData: string;
    @attr menu: string;
    @attr topMenu: string;
    @attr pageType: string = 'homepage';
    @observable webContent: IDefaultPage;
    @observable currentUrl: string = window.location.pathname;
    @observable loading: boolean = false;
    @observable showTopMenu: boolean = false;

    constructor(@RWSInject(StylingService) private stylingService: StylingServiceInstance){
        super()
    }

    async connectedCallback(): Promise<void>
    {
        super.connectedCallback();
        const page = JSON.parse(this.pageData) as IDefaultPage;

        await this.loadPage(page.url);

        this.on<string>('page:url:change', async (event: CustomEvent<string>) => {
            console.log('urlchanged', event);
            this.loadPage(event.detail);
        });

        window.addEventListener('popstate', async (event) => {
            const currentUrl = window.location.pathname;
            await this.loadPage(currentUrl);
        });
    }

    private async loadPage(url: string): Promise<void> {
        try {
            const responseData: { template: string, content: IDefaultPage } = await this.getPage(url);


            history.pushState(null, '', url);
            document.title = responseData.content.title;
            
            this.pageType = responseData.template;
            this.webContent = responseData.content;
            await this.setComponent();
        } catch(e: Error | any){
            console.error(e);
            alert('Błąd ładowania strony')
        }
    }

    private async setComponent(){
        const mainArea = this.$('main') as HTMLElement;
        this.loading = true;
        
        await this.stylingService.waitForTransition(mainArea);
        
        mainArea.innerHTML = '';

        const pageTemplate = document.createElement(this.pageType + '-page');
        (pageTemplate as any).webContent = this.webContent;
        (pageTemplate as any).menu = this.menu;
    
        console.log({responseData: this.webContent});

        mainArea.appendChild(pageTemplate);

        this.loading = false;

        this.showTopMenu = this.pageType !== 'homepage';
    }

    private async getPage<T = IDefaultPage>(url: string): Promise<{ template: string, content: T }>
    {
        return await this.apiService.get(url + '.json');
    }
}

EntryPoint.defineComponent();

export { EntryPoint };
